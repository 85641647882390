<template>
  <NuxtLink
    v-if="link"
    ref="button"
    :to="link"
    :target="target"
    :class="[
      baseClasses(),
      getColorClass(),
      sizeClasses[size],
      loading ? 'cursor-not-allowed opacity-75' : '',
      textSizeClasses[textSize],
    ]"
    class="test"
    :disabled="disabled || loading"
    :data-test-id="dataTest"
    @click="onClick"
  >
    <DynamicPictogram
      v-if="loading"
      class="py-1"
      name="spinner"
      color="white"
      spin
    />
    <slot v-else />
  </NuxtLink>

  <button
    v-else
    ref="button"
    :type="type"
    :class="[
      baseClasses(),
      getColorClass(),
      sizeClasses[size],
      loading ? 'cursor-not-allowed opacity-75' : '',
      textSizeClasses[textSize],
    ]"
    :disabled="disabled || loading"
    :data-test-id="dataTest"
    @click="onClick"
  >
    <DynamicPictogram
      v-if="loading"
      class="py-1"
      name="spinner"
      color="white"
      spin
    />
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
type ColorClassKeys =
  | 'primary'
  | 'secondary'
  | 'dark'
  | 'transparent'
  | 'transparentOutlined'
  | 'white'
  | 'black'
  | 'link'
  | 'disabled'
  | 'outline-supporting-dark-3'
  | 'supporting-dark-3-no-border'
  | 'supporting-black-54-no-border'
  | 'outline-supporting-black-87'
  | 'halloween';

type Size = 'sm' | 'base';

type TextSize = 'sm' | 'base' | 'md' | 'lg' | 'xl';

type ButtonType = 'button' | 'submit' | 'reset';

const sizeClasses: Record<Size, string> = {
  sm: 'h-10',
  base: 'h-11',
};

const textSizeClasses: Record<TextSize, string> = {
  sm: 'text-sm',
  base: 'text-base',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
};

const baseClasses = () =>
  `flex items-center justify-center transition ease-in-out duration-150 px-2 ${props.baseClasses}`;

const props = defineProps({
  color: {
    type: String as PropType<ColorClassKeys>,
    default: 'primary',
  },
  size: {
    type: String as PropType<Size>,
    default: 'base',
  },
  textSize: {
    type: String as PropType<TextSize>,
    default: 'base',
  },
  link: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number],
    required: false,
    default: 0,
  },
  type: {
    type: String as () => ButtonType,
    default: 'submit',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  focus: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String,
    default: '_self',
  },
  dataTest: {
    type: String,
    default: '',
  },
  baseClasses: {
    type: String,
    default: '',
  },
  primaryStyle: {
    type: String,
    default: 'bg-primary-default hover:bg-primary-hover text-white',
  },
  secondaryStyle: {
    type: String,
    default: 'bg-black/6 text-black disabled:bg-white hover:bg-black/12',
  },
  darkStyle: {
    type: String,
    default: 'bg-gray-700 text-white disabled:bg-gray-700 hover:bg-gray-900',
  },
  blackStyle: {
    type: String,
    default: 'bg-black/87 text-white disabled:bg-black/87 hover:bg-black/54',
  },
  transparentStyle: {
    type: String,
    default:
      'bg-transparent text-primary-default disabled:shadow-none hover:text-primary-hover',
  },
  transparentOutlinedStyle: {
    type: String,
    default:
      'bg-transparent text-primary-default disabled:shadow-none hover:shadow-inner border border-solid border-primary-default',
  },
  whiteStyle: {
    type: String,
    default: 'bg-white text-black disabled:shadow-none hover:shadow-inner',
  },
  linkStyle: {
    type: String,
    default: 'bg-transparent text-primary-default font-normal',
  },
  disabledStyle: {
    type: String,
    default: 'bg-black/6 text-black/26 cursor-not-allowed',
  },
  outlineSupportingDark3Style: {
    type: String,
    default:
      'bg-transparent text-supporting-dark-3 border border-supporting-dark-3',
  },
  outlineSupportingBlack87Style: {
    type: String,
    default: 'bg-transparent text-black/87 border border-black/87',
  },
  supportingDark3NoBorderStyle: {
    type: String,
    default: 'bg-transparent text-supporting-dark-3',
  },
  supportingBlack54NoBorderStyle: {
    type: String,
    default: 'bg-transparent text-supporting-black-54',
  },
});

const colorClasses: Record<ColorClassKeys, string> = {
  primary: `${props.primaryStyle}`,
  secondary: `${props.secondaryStyle}`,
  dark: `${props.darkStyle}`,
  transparent: `${props.transparentStyle}`,
  transparentOutlined: `${props.transparentOutlinedStyle}`,
  white: `${props.whiteStyle}`,
  link: `${props.linkStyle}`,
  disabled: `${props.disabledStyle}`,
  'outline-supporting-dark-3': `${props.outlineSupportingDark3Style}`,
  'supporting-dark-3-no-border': `${props.supportingDark3NoBorderStyle}`,
  'supporting-black-54-no-border': `${props.supportingBlack54NoBorderStyle}`,
  'outline-supporting-black-87': `${props.outlineSupportingBlack87Style}`,
  black: `${props.blackStyle}`,
  halloween: 'btn-halloween text-white',
};

const emit = defineEmits(['click']);

const getColorClass = (): string => {
  const colorKey = props.disabled
    ? 'disabled'
    : (props.color as ColorClassKeys);
  return colorClasses[colorKey];
};

const onClick = (event: MouseEvent) => {
  // Blur element to avoid any lingering focus outline after click
  (event.target as HTMLElement).blur();

  if (!props.disabled && !props.loading) {
    emit('click', event);
  }
};
</script>
